var Vue = require('vue')
var Odometer = require('odometer')

Vue.component('Counter', {
  props: {
    infostat: Number
  },
  data: () => ({
    currentNumber: 0
  }),
  created () {
    window.addEventListener('scroll', () => {
      var item = window.document.getElementById('awardsContainer')
      var elPosition = item.offsetTop
      var windowBottom = window.screen.height + window.pageYOffset

      if (windowBottom > (elPosition + 200)) {
        var el = this.$refs.stat
        var new_odo = new Odometer({
          el: el
        })
        new_odo.update(this.infostat)
      }
    })
  }
})