var Vue = require('vue')
var Swiper = require('swiper').default

Vue.component('TestimonialCarousel', {
  mounted () {
    this.createSwiper()
    this.animateLeftArrow()
    this.animateRightArrow()
  },
  methods: {
    createSwiper () {
      this.mySwiper = new Swiper('.testimonial-carousel.swiper-container', {
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 800,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: false,
          bulletClass: 'testimonial-carousel__page',
          bulletActiveClass: 'testimonial-carousel__page--active'
        }
      })
    },
    nextSlide () {
      this.mySwiper.slideNext()
    },
    prevSlide () {
      this.mySwiper.slidePrev()
    },
    animateLeftArrow () {
      var el = document.getElementById('border')
      var leftArrow = document.getElementById('arrowLeft')

      el.addEventListener('mouseover', () => {
        if (!leftArrow.classList.contains('arrow-grow') && !leftArrow.classList.contains('arrow-shrink')) {
        leftArrow.classList.add('arrow-grow')
        } else if (leftArrow.classList.contains('arrow-shrink')) {
        leftArrow.classList.remove('arrow-shrink')
        leftArrow.classList.add('arrow-grow')
        }
      }, false)
      el.addEventListener('mouseout', () => {
        if (leftArrow.classList.contains('arrow-grow')) {
        leftArrow.classList.remove('arrow-grow')
        leftArrow.classList.add('arrow-shrink')
        }
      }, false)
    },
    animateRightArrow () {
      var el = document.getElementById('border')
      var rightArrow = document.getElementById('arrowRight')

      el.addEventListener('mouseover', () => {
        if (!rightArrow.classList.contains('arrow-grow') && !rightArrow.classList.contains('arrow-shrink')) {
        rightArrow.classList.add('arrow-grow')
        } else if (rightArrow.classList.contains('arrow-shrink')) {
        rightArrow.classList.remove('arrow-shrink')
        rightArrow.classList.add('arrow-grow')
        }
      }, false)
      el.addEventListener('mouseout', () => {
        if (rightArrow.classList.contains('arrow-grow')) {
        rightArrow.classList.remove('arrow-grow')
        rightArrow.classList.add('arrow-shrink')
        }
      }, false)
    }
  }
})