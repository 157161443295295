var Vue = require('vue')
var gsap = require('gsap')
var { TimelineLite, TweenLite } = gsap

Vue.component('HomeHero', {
  mounted () {
    var tl = new TimelineLite()

    tl.fromTo(this.$refs.background, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.75 })
      .fromTo(this.$refs.background, { width: "106%", marginLeft: "-3%" }, { width: "100%", marginLeft: "0", duration: 0.75 })
      .fromTo(this.$refs.text, { translateY: 50, autoAlpha: 0 }, { translateY: 0, autoAlpha: 1, duration: 0.75 })
      .fromTo(this.$refs.news, { translateY: 50, autoAlpha: 0 }, { translateY: 0, autoAlpha: 1, duration: 0.75 }, "-=0.5")

  }
})