var Vue = require('vue')
var gsap = require('gsap')
var { TimelineLite } = gsap

Vue.component('IndustryTileButton', {
  data: () => ({
    activeTile: undefined,
    isMobile: undefined
  }),
  methods: {
    openTile (tile) {
      var tl = new TimelineLite()

      tl.to(this.$refs.contentLine, { translateX: 0 })
        .fromTo(this.$refs.btnLine, { translateX: 0, autoAlpha: 1 }, { translateX: 50, autoAlpha: 0, duration: 0.1 })
        .fromTo(this.$refs.btnLabel, { translateX: 0, autoAlpha: 1 }, { translateX: 50, autoAlpha: 0, duration: 0.24 })
        .fromTo(this.$refs.btnIcon, { translateX: 0, autoAlpha: 1 }, { translateX: 50, autoAlpha: 0, duration: 0.25 })
        .fromTo(this.$refs.tileContentWrapper, { display: 'none' }, { display: 'flex', duration: 0.1 })
        .fromTo(this.$refs.tileContentWrapper, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.25 })
        .fromTo(this.$refs.tileContentWrapper, { translateX: -2000 }, { translateX: 0, duration: 0.75 })
        .fromTo(this.$refs.closeBtn, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.25 })
        .fromTo(this.$refs.bgImage, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.75 })
        .fromTo(this.$refs.contentWrapperInner, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.25 }, "-=0.5")
        .fromTo(this.$refs.contentIcon, { translateX: this.isMobile? -50 : 0, autoAlpha: 0 }, { translateX: this.isMobile? 0 : 50, autoAlpha: 1, duration: 0.5 }, "-=0.51")
        .fromTo(this.$refs.contentTitle, { translateX: -50, autoAlpha: 0 }, { translateX: 0, autoAlpha: 1, duration: 0.5 }, "-=0.5")
        .fromTo(this.$refs.content, { translateX: -50, autoAlpha: 0 }, { translateX: 0, autoAlpha: 1, duration: 0.49 }, "-=0.49")
        .fromTo(this.$refs.contentLine, { autoAlpha: 0, width: 0 }, { autoAlpha: 1, width: 153, duration: 0.25 }, "-=0.25")

      this.activeTile = tile
      tl.play()
    },
    closeTile () {
      var tl = new TimelineLite()

      tl.fromTo(this.$refs.closeBtn, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.25 })
        .fromTo(this.$refs.bgImage, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.75 })
        .fromTo(this.$refs.content, { translateX: 0, autoAlpha: 1 }, { translateX: 50, autoAlpha: 0, duration: 0.49 })
        .fromTo(this.$refs.contentTitle, { translateX: 0, autoAlpha: 1 }, { translateX: 50, autoAlpha: 0, duration: 0.5 }, "-=0.50")
        .fromTo(this.$refs.contentIcon, { translateX: this.isMobile ? 0 : 50, autoAlpha: 1 }, { translateX: this.isMobile ? 50 : 100, autoAlpha: 0, duration: 0.51 }, "-=0.51")
        .fromTo(this.$refs.contentLine, { translateX: 0, autoAlpha: 1 }, {translateX: 50, autoAlpha: 0, duration: 0.25 }, "-=0.25")
        .fromTo(this.$refs.contentWrapperInner, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.24 })
        .fromTo(this.$refs.tileContentWrapper, { translateX: 0 }, { translateX: 2000, duration: 0.75 })
        .fromTo(this.$refs.tileContentWrapper, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.25 })
        .fromTo(this.$refs.tileContentWrapper, { display: 'flex' }, { display: 'none', duration: 0.1 })
        .fromTo(this.$refs.btnIcon, { translateX: 50, autoAlpha: 0 }, { translateX: 0, autoAlpha: 1, duration: 0.25 })
        .fromTo(this.$refs.btnLabel, { translateX: 50, autoAlpha: 0 }, { translateX: 0, autoAlpha: 1, duration: 0.24 })
        .fromTo(this.$refs.btnLine, { translateX: 50, autoAlpha: 0 }, { translateX: 0, autoAlpha: 1, duration: 0.05 }, "-=0.23")

      this.activeTile = undefined
      tl.play()

    },
    isActiveTile (tile) {
      return this.activeTile === tile
    },
    checkIsMobile() {
      let mql = window.matchMedia('(max-width:1100px)');
      const setIsMobile = (e) => {
          this.isMobile = e.matches;
          return e.matches
      }
      this.isMobile = mql.matches; //init isMobile value

      mql.addEventListener('change', setIsMobile);
    }
  },
  mounted() {
    this.checkIsMobile();
  }
})